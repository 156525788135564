<template>
    <span>
        <svg :style="style" :width="iconWidth" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6803 30.2934C19.4536 30.2934 19.2402 30.24 19.0669 30.1334C18.4669 29.7467 15.4803 27.68 14.9336 24.5334C14.6403 22.7867 15.1336 21.0534 16.4136 19.4C20.1336 14.5867 20.5469 13.1867 20.5602 13.1334C20.6136 12.76 20.8669 12.4934 21.2269 12.3734C21.5869 12.2534 21.9602 12.3734 22.2402 12.64C25.2802 15.5867 26.6669 18.6267 26.3469 21.68C25.8403 26.4667 21.2003 29.6 20.2803 30.1734C20.1203 30.24 19.8936 30.2934 19.6803 30.2934ZM21.8403 15.08C21.2269 16.16 20.1069 17.8934 18.0002 20.6C17.0536 21.8134 16.7069 22.9867 16.9069 24.1734C17.2269 26.0267 18.8536 27.48 19.6936 28.1067C21.0136 27.1867 24.0003 24.76 24.3469 21.4134C24.5736 19.36 23.7336 17.24 21.8403 15.08Z" :fill="color ? color : iconColor"/>
            <path d="M13.7068 30.3067C13.3601 30.3067 13.0134 30.2133 12.7601 30.1467C9.81344 29.3067 7.14679 26.6133 6.10679 23.4533C5.04012 20.2133 5.69339 16.8267 7.96006 13.9067C12.0934 8.49332 13.4668 4.13332 13.7868 2.93332C13.9335 2.35999 14.3601 1.91999 14.9468 1.74665C15.5468 1.58665 16.2001 1.75999 16.6534 2.21332C16.6934 2.23999 16.7201 2.27999 16.7468 2.31999C18.0001 3.99999 19.3468 6.99999 19.3468 10.3867C19.3468 12.7067 17.7201 14.8133 16.1467 16.84C14.4801 19 12.7601 21.2267 12.9468 23.64C13.1201 25.4267 13.8934 26.7867 14.4134 27.6933C14.8534 28.4533 15.2668 29.1733 14.7468 29.8533C14.4668 30.2 14.0935 30.3067 13.7068 30.3067ZM13.0001 29.2933H13.0134H13.0001ZM15.5335 4.07999C14.9201 6.09332 13.3201 10.1733 9.54673 15.1333C7.18673 18.1867 7.41344 21.0133 8.01344 22.84C8.72011 24.9867 10.3201 26.8533 12.1868 27.7867C11.6801 26.8 11.1201 25.4667 10.9601 23.8133C10.7201 20.6133 12.7601 17.96 14.5734 15.6133C16.0001 13.76 17.3468 12.0133 17.3468 10.3733C17.3468 7.83999 16.4401 5.51999 15.5335 4.07999ZM15.7201 3.42665C15.7201 3.42665 15.7201 3.43999 15.7201 3.42665C15.7201 3.43999 15.7201 3.42665 15.7201 3.42665Z" :fill="color ? color : iconColor"/>
        </svg>
    </span>
</template>

<script>
export default {
    name: "FireIcon",
    data() {
        return {
            iconWidth: 24,
            iconColor: 'black',
        }
    },
    props: {
        width: String,
        color: String,
        moveX: String,
        moveY: String
    },
    computed: {
        style () {
            let rule = '';
            if (this.moveX) { rule += `left: ${this.moveX}px;`; }
            if (this.moveY) { rule += `top: ${this.moveY}px;`; }
            return rule;
        }
    },
    created() {
        if (this.width) { this.iconWidth = parseInt(this.width); }
        if (this.color) { this.iconColor = this.color; }
    }
}
</script>

<style scoped>

</style>