import { createStore } from 'vuex'
import auth from './modules/auth';
import data from './modules/data';
import discounts from './modules/discounts';
import facilities from './modules/facilities';
import parlour from './modules/parlour';
import regions from './modules/regions';
import step from './modules/step';
import catalog from './modules/catalog';
import cart from "./modules/cart";
import promo from './modules/promo';

export default createStore({
    state: {
        localStorageKey: 'payDocWidget',
        localStorageModules: [ 'data', 'cart', 'catalog', 'facilities', 'parlour', 'regions', 'step' ]
    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async set(context) {
            const promises = [
                context.dispatch('regions/set'),
            ];
            const result = await Promise.all(promises);
            return result.every(res => res);
        },
        saveStore(context) {
            let storeObj = {};
            context.state.localStorageModules.forEach(module => {
                storeObj[module] = context.rootGetters[`${module}/getState`];
            });
            localStorage.setItem(`${context.state.localStorageKey}/store`, JSON.stringify(storeObj));
        },
        async initStore(context) {
            let storeObj = localStorage.getItem(`${context.state.localStorageKey}/store`);
            if (!storeObj) return false;
            storeObj = JSON.parse(storeObj);
            for (let module of context.state.localStorageModules) {
                await context.dispatch(`${module}/setState`, storeObj[module]);
            }
            return true;
        },
        async clearStore(context) {
            localStorage.removeItem(`${context.state.localStorageKey}/store`);
            for (let module of context.state.localStorageModules) {
                await context.dispatch(`${module}/reset`);
            }
        }
    },
    modules: {
        auth: auth,
        data: data,
        discounts: discounts,
        facilities: facilities,
        parlour: parlour,
        regions: regions,
        step: step,
        catalog: catalog,
        cart: cart,
        promo: promo
    }
})