<template>
    <div class="main">
        <div class="icons">
            <q-icon
                :name="icon.arrowLeft"
                size="17px"
                v-on:click="$store.dispatch('step/prevStep')"
                style="color: #DCE1E8;"
                class="cursor-pointer"
            />
        </div>
        <p class="title text-center">{{ title }}</p>
        <p class="thanks-text text-center">{{ thanksText }}</p>
        <FormButton width="100%" @click="okAction">Ок</FormButton>
        <div class="social-links text-center">
            <WhatsAppIcon width="32px" class="social-icon cursor-pointer" @click="link('wa')" />
            <TelegramIcon width="32px" class="social-icon cursor-pointer" @click="link('tg')" />
        </div>
    </div>
</template>

<script>
import { fasChevronLeft } from "@quasar/extras/fontawesome-v6";
import FormButton from "@/components/Form/FormButton";
import WhatsAppIcon from "@/components/Icon/WhatsAppIcon";
import TelegramIcon from "@/components/Icon/TelegramIcon";

/**
 * @property {Store} $store
 */
export default {
    name: "ThanksBlock",
    components: {
        FormButton,
        WhatsAppIcon,
        TelegramIcon
    },
    data() {
        return {
            title: 'Подарочный сертификат оформлен',
            thanksText: 'Спасибо, что выбрали ТвойТай',
            icon: {
                arrowLeft: null
            },
            links: {
                wa: process.env.VUE_APP_WA_LINK,
                tg: process.env.VUE_APP_TG_LINK
            }
        };
    },
    methods: {
        link(socialId) {
            window.open(this.links[socialId],'_blank');
        },
        async okAction() {
            await this.$store.dispatch('clearStore');
            await this.$store.dispatch('set');
            await this.$store.dispatch('step/setStep', 'ChooseCity');
        }
    },
    created() {
        this.icon.arrowLeft = fasChevronLeft;
    }
}
</script>

<style scoped lang="scss">
p { margin-bottom: 40px; }

.thanks-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $primary-color;
}

.social-links {
    margin-top: 17px;
}

.social-icon {
    margin: 0 12px;
}

.main {
    padding-bottom: 60px !important;
}
</style>