import axios from "axios";

function defaultState() {
    return {
        apiUrl: process.env.VUE_APP_URL_API + '/public-api/paydoc/promo',
        promo: false,
        moneyCertificateTitle: 'Сертификат на сумму',
        moneyCertificatesTypes: [ 0.5, 0.75, 1, 1.5, 2 ],
        customCertificates: {}
    };
}

/**
 * Модуль для услуг с акциями1
 */
export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getState(state) { return state; },
        promo(state) { return state.promo; },

        /**
         * Get promo by id
         * @param {Object} state
         * @return function
         */
        getPromoById(state) {
            return function(promoId) {
                for (let index = 0; index < state.promo.length; index++) {
                    if (promoId === state.promo[index].id) {
                        return state.facilities[index];
                    }
                }
                return false;
            };
        },
    },
    actions: {
        setState(context, newState) { context.commit('setState', newState); },
        reset(context) { context.commit('reset'); },

        /**
         * Fetch api and set promo array
         * @param {Object} context
         * @param {String} regionId
         */
        async set(context, regionId) {
            let response = await axios.get(context.state.apiUrl, {
                params: {
                    per_page: -1,
                    'search[region]': regionId,
                    'search[active]': 1
                },
                headers: { Authorization: `ApiKey ${process.env.VUE_APP_TOKEN}` }
            });
            response = response.data;
            if (response.success) {
                const promos = response.data.map(promo => {
                    const discountPercent = 1 - promo.discountValue / 100;
                    const minRefillLimit = promo.type.refillLimits && promo.type.refillLimits.min ? promo.type.refillLimits.min : 1;
                    promo.oldPrice = context.rootGetters['regions/regionCost'](regionId) * minRefillLimit;
                    promo.price = Math.round(discountPercent * promo.oldPrice);
                    promo.time = minRefillLimit;
                    return promo;
                });
                context.commit('setPromos', promos);
            }
            return response.success;
        },
    },
    mutations: {
        setState(state, newState) {
            for (let prop in state) {
                state[prop] = newState[prop];
            }
        },
        reset(state) {
            const defState = defaultState();
            Object.keys(defState).forEach(stateKey => {
                state[stateKey] = defState[stateKey];
            })
        },

        setPromos(state, promosData) { state.promo = promosData; },
    }
}