export default {
    /**
     * Validate phone number
     * @param {String|Number} phone
     * @return Boolean
     */
    phone(phone) {
        return !!String(phone).match(/^\d{11,13}$/);
    },

    /**
     * Validate email
     * @param {String} email
     * @return Boolean
     */
    email(email) {
        return !!email.match(/^[\w-.]+@[\w-]+\.\w+$/);
    },

    /**
     * Validate user name
     * @param {String} name
     * @return Boolean
     */
    name(name) {
        return !!name.match(/[\wа-яА-Я][\wа-яА-Я\s]+/);
    },

    /**
     * Validate address
     * @param {String} address
     * @return Boolean
     */
    address(address) {
        return !!address.match(/[\wа-яА-Я][\wа-яА-Я\s]+/);
    }
}