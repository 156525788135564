import slug from "slug";
import _ from "lodash";

export function fire(goal, params) {
    const g = slug(goal, "_");
    const s = _.mapValues(params, function (p) {
        return slug(p, "_");
    });

    try {
        console.log(g);
        console.log(s);
        window.yaCounter91998987.reachGoal(g, s);
    } catch (e) {
        console.log("Seems Yandex.Metrika not loaded");
    }
}
