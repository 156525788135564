<template>
    <span>
        <svg :style="style" :width="width" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0035 1.04688H15.9957C7.77329 1.04688 1.08691 7.75319 1.08691 16C1.08691 19.271 2.1381 22.303 3.92579 24.7645L2.06754 30.3191L7.79979 28.4819C10.1576 30.0484 12.9705 30.9531 16.0035 30.9531C24.226 30.9531 30.9124 24.2446 30.9124 15.9999C30.9124 7.75519 24.2259 1.04688 16.0035 1.04688Z" fill="#5ACF5F"/>
            <path d="M24.6801 22.1627C24.3203 23.1817 22.8924 24.0263 21.7536 24.2734C20.9741 24.4395 19.9565 24.5725 16.5306 23.148C12.1478 21.3276 9.32565 16.8621 9.10596 16.5724C8.89502 16.2826 7.33496 14.2082 7.33496 12.0621C7.33496 9.91641 8.42159 8.87134 8.85984 8.42316C9.21927 8.05459 9.81402 7.88672 10.3845 7.88672C10.5691 7.88672 10.7348 7.89584 10.8843 7.90341C11.3223 7.92228 11.542 7.94822 11.8312 8.64172C12.191 9.51103 13.0668 11.6567 13.1715 11.8774C13.2778 12.098 13.3838 12.3968 13.2348 12.6865C13.0948 12.9857 12.972 13.1183 12.752 13.3724C12.5319 13.627 12.3229 13.8213 12.1031 14.0939C11.902 14.3313 11.6745 14.5856 11.9281 15.0249C12.1815 15.4548 13.0575 16.8882 14.3473 18.0396C16.0123 19.5255 17.3616 20.0003 17.8446 20.2023C18.2043 20.352 18.633 20.3162 18.8956 20.0359C19.2293 19.675 19.6417 19.077 20.0609 18.4885C20.3591 18.066 20.7356 18.0135 21.1308 18.163C21.5334 18.3032 23.664 19.3594 24.1021 19.578C24.54 19.7986 24.8293 19.9032 24.9355 20.0883C25.0399 20.273 25.0399 21.1424 24.6801 22.1627Z" fill="#FCFCFC"/>
        </svg>
    </span>
</template>

<script>
export default {
    name: "WhatsAppIcon",
    data() {
        return {
            iconWidth: 16,
            iconColor: 'black',
        }
    },
    props: {
        width: String,
        color: String,
        moveX: String,
        moveY: String
    },
    computed: {
        style () {
            let rule = '';
            if (this.moveX) { rule += `left: ${this.moveX}px;`; }
            if (this.moveY) { rule += `top: ${this.moveY}px;`; }
            return rule;
        }
    },
    created() {
        if (this.width) { this.iconWidth = parseInt(this.width); }
    }
}
</script>

<style scoped>
/*span { position: relative; }*/
/*svg { position: absolute; }*/
</style>
