<template>
    <div class="phone-number">
        <FormInput
            :layout="layout"
            :errorMsg="errorMsg"
            :placeholder="placeholder"
            v-model="phone"
            ref="input"
            @input="updatePhoneOnChange"
        />
    </div>
</template>

<script>
import FormInput from "@/components/Form/FormInput";
import {mapGetters} from "vuex";

export default {
    name: "NumberField",
    components: {
        FormInput
    },
    props: [
        'placeholder',
        'layout',
        'errorMsg'
    ],
    computed: {
        ...mapGetters({
            certData: 'data/certData'
        }),
        phone: {
            get() { return this.certData.phone; },
            set(value) {
                const key = 'phone';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        /**
         * Return only digits from phone number
         * @return String
         */
        phoneDigits() {
            return String(this.phone).replaceAll(/\D/g, '');
        }
    },
    methods: {
        updatePhoneOnChange(event) {
            let phone = event.target.value.replaceAll(/\D/g, '');
            if (phone[0] === '8') phone = '7' + phone.slice(1);
            else if (phone[0] === '9') phone = '79' + phone.slice(1);
            else if (phone.length === 1 && phone[0] !== '7') phone = '79' + phone;
            else if (phone[0] !== '7') phone = '7' + phone.slice(1);
            if (phone.length > 11) phone = phone.slice(0, 11);
            phone = phone.replace(/^7(\d{3})(\d{3})(\d{2})(\d{1,2})$/, '+7 ($1) $2 $3 $4');
            phone = phone.replace(/^7(\d{3})(\d{3})(\d{1,2})$/, '+7 ($1) $2 $3');
            phone = phone.replace(/^7(\d{3})(\d{0,3})$/, '+7 ($1) $2');
            phone = phone.replace(/^7(\d{2,3})$/, '+7$1');
            phone = phone.replace(/^7(\d?)$/, '+7$1');
            if (event.inputType === 'deleteContentBackward' && phone.length && phone.match(/\D$/)) {
                phone = phone.slice(0, -2);
            }
            this.$refs.input.updateText(phone);
        }
    }
}
</script>

<style scoped>
.phone-number { margin-bottom: 16px; }
</style>