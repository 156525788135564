<template>
    <span>
        <svg :width="iconWidth" :style="style" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 9.25H2C1.59 9.25 1.25 8.91 1.25 8.5C1.25 8.09 1.59 7.75 2 7.75H13.5C13.91 7.75 14.25 8.09 14.25 8.5C14.25 8.91 13.91 9.25 13.5 9.25Z" :fill="color ? color : iconColor"/>
            <path d="M8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25Z" :fill="color ? color : iconColor"/>
            <path d="M14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z" :fill="color ? color : iconColor"/>
            <path d="M17.56 21.25H6.44C2.46 21.25 1.25 20.05 1.25 16.11V7.89C1.25 3.95 2.46 2.75 6.44 2.75H13.5C13.91 2.75 14.25 3.09 14.25 3.5C14.25 3.91 13.91 4.25 13.5 4.25H6.44C3.3 4.25 2.75 4.79 2.75 7.89V16.1C2.75 19.2 3.3 19.74 6.44 19.74H17.55C20.69 19.74 21.24 19.2 21.24 16.1V12.02C21.24 11.61 21.58 11.27 21.99 11.27C22.4 11.27 22.74 11.61 22.74 12.02V16.1C22.75 20.05 21.54 21.25 17.56 21.25Z" :fill="color ? color : iconColor"/>
            <path d="M22 7H16.5C16.09 7 15.75 6.66 15.75 6.25C15.75 5.84 16.09 5.5 16.5 5.5H22C22.41 5.5 22.75 5.84 22.75 6.25C22.75 6.66 22.41 7 22 7Z" :fill="color ? color : iconColor"/>
            <path d="M19.25 9.75C18.84 9.75 18.5 9.41 18.5 9V3.5C18.5 3.09 18.84 2.75 19.25 2.75C19.66 2.75 20 3.09 20 3.5V9C20 9.41 19.66 9.75 19.25 9.75Z" :fill="color ? color : iconColor"/>
        </svg>
    </span>
</template>

<script>
export default {
    name: "CardAddIcon",
    data() {
        return {
            iconWidth: 24,
            iconColor: 'black',
        }
    },
    props: {
        width: String,
        color: String,
        moveX: String,
        moveY: String
    },
    computed: {
        style () {
            let rule = '';
            if (this.moveX) { rule += `left: ${this.moveX}px;`; }
            if (this.moveY) { rule += `top: ${this.moveY}px;`; }
            return rule;
        }
    },
    created() {
        if (this.width) { this.iconWidth = parseInt(this.width); }
    }
}
</script>

<style scoped>

</style>