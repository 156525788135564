function defaultState() {
    return {
        step: null,
        prevSteps: []
    };
}

/**
 * Модуль для хранения состояния виджета
 */
export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getState(state) { return state; },

        /**
         * Get current step
         * @param state
         * @return string|null
         */
        step(state) { return state.step; }
    },
    actions: {
        setState(context, newState) { context.commit('setState', newState); },
        reset(context) { context.commit('reset'); },

        /**
         * Fetch api and set regions array
         * @param context
         */
        prevStep(context) {
            context.commit('prevStep');
        },

        setStep(context, step) {
            context.commit('setStep', step);
        }
    },
    mutations: {
        setState(state, newState) {
            for (let prop in state) {
                state[prop] = newState[prop];
            }
        },
        reset(state) {
            const defState = defaultState();
            Object.keys(defState).forEach(stateKey => {
                state[stateKey] = defState[stateKey];
            })
        },

        /**
         * Set ordered steps array
         * @param state
         * @param step Current step
         */
        setStep(state, step) {
            state.step = step;
            state.prevSteps.push(step);
        },

        /**
         * Set previous step
         * @param state
         */
        prevStep(state) {
            state.prevSteps.pop();
            state.step = state.prevSteps[state.prevSteps.length - 1];
        },
    }
}