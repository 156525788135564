<template>
    <div class="comment">
        <FormTextarea
            width="100%"
            placeholder="Добавить комментарий"
            v-model="comment"
        />
    </div>
</template>

<script>
import FormTextarea from "@/components/Form/FormTextarea";
import {mapGetters} from "vuex";

export default {
    name: "TextareaField",
    components: {
        FormTextarea
    },
    computed: {
        ...mapGetters({
            certData: 'data/certData',
        }),
        comment: {
            get() { return this.certData.comment; },
            set(value) {
                const key = 'comment';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
    }
}
</script>

<style scoped>

</style>