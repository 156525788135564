<template>
    <div class="card-list service" v-if="section === 'service'">
        <ServiceCard
            v-for="facility in facilities" :key="facility"
            :type="section"
            :id="facility.id"
            :head="facility.name"
            :description="facility.description"
            :price="facility.price"
        />
    </div>
    <div class="card-list sum" v-if="section === 'sum'">
        <ServiceCard
            v-for="cert in moneyCertificates" :key="cert"
            :type="section"
            :id="cert.id"
            :head="moneyCertificateTitle"
            :price="cert.price"
        />
    </div>
    <div class="card-list sale" v-if="section === 'sale'">
        <ServiceCard
            v-for="promo in promos" :key="promo"
            :type="section"
            :id="promo.id"
            :head="promo.name"
            :description="promo.description"
            :price="promo.price"
            :oldPrice="promo.oldPrice"
            :promoTypeCode="promo.type.code"
            :typeObject="promo"
        />
    </div>
</template>

<script>
import ServiceCard from "@/components/Catalog/ServiceCard";
import {mapGetters} from "vuex";

export default {
    name: "CardList",
    components: {
        ServiceCard
    },
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters({
            facilities: 'facilities/facilities',
            section: 'catalog/section',
            moneyCertificates: 'facilities/moneyCertificates',
            moneyCertificateTitle: 'facilities/moneyCertificateTitle',
            promos: 'promo/promo'
        })

    },
    created() {

    },
    methods: {

    }
}
</script>

<style scoped>
.card-list {
    height: 400px;
    overflow-y: scroll;
}

@media screen and (max-height: 800px) {
    .card-list {
        height: 280px;
        overflow-y: visible;
    }
}

@media screen and (max-height: 700px) {
    .card-list.sum {
        height: 200px;
    }
}

@media screen and (max-height: 600px) {
    .card-list { height: 200px; }
    .card-list.sum { height: 140px; }
}

</style>