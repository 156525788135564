<template>
    <div class="custom-sum-field">
        <div>
            <div class="custom-sum-input">
                <FormInput placeholder="Любая сумма сертификата" v-model="sum" mask="#" ref="input"/>
            </div>
            <div class="custom-sum-btn text-right">
                <FormButton :width="$root.isMobile ? '90px' : '126px'" :layout="btnLayout" @click="setCustomSum">Готово</FormButton>
            </div>
        </div>
    </div>
</template>

<script>
import FormInput from "@/components/Form/FormInput";
import FormButton from "@/components/Form/FormButton";

export default {
    name: "CustomSumField",
    components: {
        FormInput,
        FormButton
    },
    data() {
        return {
            sum: ''
        }
    },
    methods: {
        setCustomSum() {
            if (Number(this.sum) > 0) {
                this.$store.dispatch('facilities/setCustomCertificate', Number(this.sum));
                this.$store.dispatch('data/addMoneyCertificate', Number(this.sum));
                this.sum = '';
                this.$refs.input.updateText('');
            }
        }
    },
    computed: {
        btnLayout() {
            return parseInt(this.sum) > 0 ? 'primary' : 'disabled';
        }
    },
}
</script>

<style scoped lang="scss">
.custom-sum-field {
    background: url("@/assets/img/CustomSumBackground.png") no-repeat;
    -moz-background-size: 100%; /* Firefox 3.6+ */
    -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
    -o-background-size: 100%; /* Opera 9.6+ */
    background-size: 100%; /* Современные браузеры */

    display: table;
    padding: 32px 16px;
    width: 100%;
}

.custom-sum-field>div {
    display: table-row;
}

.custom-sum-input, .custom-sum-btn { display: table-cell; }

.custom-sum-input {
    width: 215px;
}

@media screen and (max-width: $main-width) {
    .custom-sum-input {
        width: 60%;
    }
}

@media screen and (max-height: 600px) {
    .custom-sum-field {
        padding: 10px 16px;
    }
}
</style>