function defaultState() {
    return {

    };
}

/**
 * Модуль для скидок с данными
 */
export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getState(state) { return state; },
    },
    actions: {
        setState(context, newState) { context.commit('setState', newState); },
        reset(context) { context.commit('reset'); },
    },
    mutations: {
        setState(state, newState) {
            for (let prop in state) {
                state[prop] = newState[prop];
            }
        },
        reset(state) {
            const defState = defaultState();
            Object.keys(defState).forEach(stateKey => {
                state[stateKey] = defState[stateKey];
            })
        },
    }
}