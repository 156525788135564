export function getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length === 2) {
        const popped = parts.pop();
        if (popped !== undefined) {
            return popped.split(";").shift();
        }
    }
}