import axios from 'axios';

function defaultState() {
    return {
        apiUrl: process.env.VUE_APP_URL_API + '/public-api/paydoc/regions',
        regions: null
    };
}

/**
 * Модуль для регионов с данными
 */
export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getState(state) { return state; },

        /**
         * Get all regions
         * @param state
         * @return array
         */
        regions(state) { return Object.values(state.regions); },

        /**
         * @param {Object} state
         * @return function
         */
        regionCost(state)
        {
            return function(regionId) {
                return state.regions[regionId].price.cost.val;
            };
        },

        /**
         * @param {Object} state
         * @return function
         */
        regionById(state)
        {
            return function (regionId) {
                return state.regions[regionId];
            };
        }
    },
    actions: {
        setState(context, newState) { context.commit('setState', newState); },
        reset(context) { context.commit('reset'); },

        /**
         * Fetch api and set regions array
         * @param context
         */
        async set(context) {
            let response = await axios.get(context.state.apiUrl, {
                headers: { Authorization: `ApiKey ${process.env.VUE_APP_TOKEN}` }
            });
            response = response.data;
            if (response.success) {
                const regions = response.data.reduce((a, v) => ({ ...a, [v.id]: v}), {});
                context.commit('setRegions', regions);
            }
            return response.success;
        }
    },
    mutations: {
        setState(state, newState) {
            for (let prop in state) {
                state[prop] = newState[prop];
            }
        },
        reset(state) {
            const defState = defaultState();
            Object.keys(defState).forEach(stateKey => {
                state[stateKey] = defState[stateKey];
            })
        },

        setRegions(state, regionsData) { state.regions = regionsData; }
    }
}