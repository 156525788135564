<template>
    <div class="email">
        <FormInput placeholder="Электронная почта покупателя*" v-model="email" :layout="layout" :errorMsg="errorMsg" />
    </div>
</template>

<script>
import FormInput from "@/components/Form/FormInput";
import {mapGetters} from "vuex";

export default {
    name: "EmailField",
    props: ['layout', 'errorMsg'],
    components: {
        FormInput
    },
    computed: {
        ...mapGetters({
            certData: 'data/certData'
        }),
        email: {
            get() { return this.certData.email; },
            set(value) {
                const key = 'email';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
    }
}
</script>

<style scoped>

</style>