<template>
    <span>
        <svg :style="style" :width="width" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9997 1.33301C12.1098 1.33301 8.37931 2.87824 5.62878 5.62878C2.87824 8.37931 1.33301 12.1098 1.33301 15.9997C1.33301 19.8895 2.87824 23.62 5.62878 26.3706C8.37931 29.1211 12.1098 30.6663 15.9997 30.6663C19.8895 30.6663 23.62 29.1211 26.3706 26.3706C29.1211 23.62 30.6663 19.8895 30.6663 15.9997C30.6663 12.1098 29.1211 8.37931 26.3706 5.62878C23.62 2.87824 19.8895 1.33301 15.9997 1.33301Z" fill="#29B6F6"/>
            <path d="M22.6337 9.99968L20.1363 22.7503C20.1363 22.7503 20.029 23.333 19.3063 23.333C18.9223 23.333 18.7243 23.1503 18.7243 23.1503L13.315 18.6617L10.6683 17.3277L7.27166 16.4243C7.27166 16.4243 6.66699 16.2497 6.66699 15.7497C6.66699 15.333 7.28899 15.1343 7.28899 15.1343L21.4997 9.48901C21.499 9.48834 21.9337 9.33234 22.2503 9.33301C22.445 9.33301 22.667 9.41634 22.667 9.66634C22.667 9.83301 22.6337 9.99968 22.6337 9.99968Z" fill="white"/>
            <path d="M15.3329 20.3371L13.0489 22.5865C13.0489 22.5865 12.9496 22.6631 12.8169 22.6665C12.7709 22.6678 12.7216 22.6605 12.6709 22.6378L13.3136 18.6611L15.3329 20.3371Z" fill="#B0BEC5"/>
            <path d="M19.9317 12.1306C19.819 11.9839 19.611 11.9573 19.4643 12.0686L10.667 17.3333C10.667 17.3333 12.071 21.2613 12.285 21.9413C12.4997 22.6219 12.6717 22.6379 12.6717 22.6379L13.3143 18.6613L19.869 12.5973C20.0157 12.4859 20.043 12.2773 19.9317 12.1306Z" fill="#CFD8DC"/>
        </svg>
    </span>
</template>

<script>
export default {
    name: "WhatsAppIcon",
    data() {
        return {
            iconWidth: 16,
            iconColor: 'black',
        }
    },
    props: {
        width: String,
        color: String,
        moveX: String,
        moveY: String
    },
    computed: {
        style () {
            let rule = '';
            if (this.moveX) { rule += `left: ${this.moveX}px;`; }
            if (this.moveY) { rule += `top: ${this.moveY}px;`; }
            return rule;
        }
    },
    created() {
        if (this.width) { this.iconWidth = parseInt(this.width); }
    }
}
</script>

<style scoped>
/*span { position: relative; }*/
/*svg { position: absolute; }*/
</style>
