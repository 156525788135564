function defaultState() {
    return {
        section: null,
        defaultSection: 'sum'
    };
}

/**
 * Модуль с каталогом товаров
 */
export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getState(state) { return state; },

        /**
         * Get active section in catalog
         * @param state
         * @return string
         */
        section(state) { return state.section ? state.section : state.defaultSection; }
    },
    actions: {
        setState(context, newState) { context.commit('setState', newState); },
        reset(context) { context.commit('reset'); },

        /**
         * Set active section
         * @param {Object} context
         * @param {String} section
         * @return void
         */
        setSection(context, section) { context.commit('setSection', section); }
    },
    mutations: {
        setState(state, newState) {
            for (let prop in state) {
                state[prop] = newState[prop];
            }
        },
        reset(state) {
            const defState = defaultState();
            Object.keys(defState).forEach(stateKey => {
                state[stateKey] = defState[stateKey];
            })
        },

        /**
         * Set active section
         * @param {Object} state
         * @param {String} section
         * @return void
         */
        setSection(state, section) { state.section = section; }
    }
}