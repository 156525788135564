import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import store from './store'
import quasarUserOptions from './quasar-user-options'

const app = createApp(App);
app.use(Quasar, quasarUserOptions);
app.use(store);
app.mount('#app');
