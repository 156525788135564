<template>
    <div class="main">
        <div class="icons">
            <q-icon
                :name="icon.arrowLeft"
                size="17px"
                v-on:click="$store.dispatch('step/prevStep')"
                style="color: #DCE1E8;"
                class="cursor-pointer"
            />
        </div>
        <p class="title text-center">{{ title }}</p>
        <p class="description text-center">{{ description }}</p>
        <p class="phone text-center">
            <a :href="`tel:${phone}`">{{ phoneBeauty }}</a>
        </p>
        <p class="messenger-description text-center">{{ messengerDescription }}</p>
        <div class="social-links text-center">
            <WhatsAppIcon width="32px" class="social-icon cursor-pointer" @click="link('wa')" />
            <TelegramIcon width="32px" class="social-icon cursor-pointer" @click="link('tg')" />
        </div>
    </div>
</template>

<script>
import WhatsAppIcon from "@/components/Icon/WhatsAppIcon";
import TelegramIcon from "@/components/Icon/TelegramIcon";
import {fasChevronLeft} from "@quasar/extras/fontawesome-v6";

export default {
    name: "SupportBlock",
    components: {
        WhatsAppIcon,
        TelegramIcon
    },
    data() {
        return {
            title: 'Поддержка',
            description: 'Позвоните нам по номеру',
            messengerDescription: 'Или напишите в один\nиз мессенджеров',
            icon: {
                arrowLeft: null,
            }
        };
    },
    computed: {
        phoneBeauty() { return this.phone.replace(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1 ($2) $3 $4 $5'); },
        phone() { return String(process.env.VUE_APP_SUPPORT_PHONE); }
    },
    methods: {
        link(socialNet) {
            window.open(this.getLink(socialNet),'_blank');
        },
        getLink(socialNet) {
            switch (socialNet) {
                case 'wa':
                    return process.env.VUE_APP_WA_LINK;
                case 'tg':
                    return process.env.VUE_APP_TG_LINK;
                default:
                    return false;
            }
        }
    },
    created() {
        this.icon.arrowLeft = fasChevronLeft;
    }
}
</script>

<style scoped>
.main { padding-bottom: 64px !important; }

.description {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #3A3A3A;
}

.phone {
    margin-bottom: 56px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

.phone>a {
    text-decoration: none;
    color: #5A305D;
}

.messenger-description {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #A6A6AA;
}
</style>