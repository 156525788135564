<template>
    <div class="cart-body">
        <CertificateType :isValidate="isValidate" />
        <NumberField placeholder="Номер телефона покупателя*" :layout="validatePhone ? 'danger' : ''" errorMsg="Номер указан некорректно" />
        <EmailField class="email-field" :layout="validateEmail ? 'danger' : ''" errorMsg="Почта указана некорректно" />
        <TextareaField class="textarea-field"/>
        <CartList :withDelivery="true" />
    </div>
</template>

<script>
import CertificateType from "@/components/Cart/CertificateType";
import NumberField from "@/components/Form/NumberField";
import EmailField from "@/components/Cart/EmailField";
import TextareaField from "@/components/Cart/TextareaField";
import CartList from "@/components/Cart/CartList";
import {mapGetters} from "vuex";
import validation from "@/store/modules/validation";

export default {
    name: "CartBody",
    props: ['isValidate'],
    components: {
        CertificateType,
        NumberField,
        EmailField,
        TextareaField,
        CartList
    },
    computed: {
        ...mapGetters({
            certData: 'data/certData',
            phoneDigit: 'data/phone'
        }),
        validatePhone() { return this.isValidate && !validation.phone(this.phoneDigit); },
        validateEmail() { return this.isValidate && !validation.email(this.certData.email); }
    }
}
</script>

<style scoped lang="scss">
.cart-body { width: 100%; }
.email-field { margin-bottom: 26px; }
.textarea-field { margin-bottom: 16px; }
</style>