<template>
    <div>
        <div class="icons row">
            <div class="col-6">
                <span class="dot"></span>
            </div>
            <div class="col-6 text-right">
                <span class="dot"></span>
            </div>
        </div>
        <div class="text-center">
            <span class="ellipse" style="margin-right: 8px;"></span>
            <span class="dot"></span>
        </div>
        <div class="head">
            <span class="square" style="margin-right: 15px;"></span>
            <span class="square" style="margin-right: 15px;"></span>
            <span class="square"></span>
        </div>
        <div class="promo-code">
            <span class="square" style="margin-right: 14px;"></span>
            <span class="ellipse" style="width: 123px; height: 40px;"></span>
        </div>
        <div class="body">
            <span class="square" style="margin-bottom: 16px;"></span>
            <span class="square" style="margin-bottom: 16px;"></span>
            <span class="square" style="margin-bottom: 16px;"></span>
            <span class="square" style="margin-bottom: 16px;"></span>
            <span class="square"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CatalogSkeleton"
}
</script>

<style scoped>
.dot, .ellipse, .square {
    display: inline-block;
    background-color: #EFF1F4;
}

.icons {
    padding: 0 15px 6px;
}

.dot {
    height: 24px;
    width: 24px;
    border-radius: 50%;
}

.ellipse {
    border-radius: 100px;
    width: 100px;
    height: 24px;
}

.head {
    margin-top: 32px;
    margin-bottom: 16px;
}

.square {
    border-radius: 10px;
    width: 122px;
    height: 104px;
}

.promo-code { margin-bottom: 16px; }

.promo-code .square {
    width: 259px;
    height: 40px;
    border-radius: 8px;
}

.body {
    height: 360px;
    margin-bottom: 32px;
    overflow: scroll;
}

.body .square {
    width: 100%;
    height: 172px;
}
</style>