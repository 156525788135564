<template>
    <div :class="['card', getTypeClass]">
        <div :class="[{'blur' : isBlur}]">
            <div class="service-img">
                <InfoIcon class="info" color="white" width="28px" layout="service" :popupText="description" />
                <div v-if="type !== 'sum' && timeStr !== null" class="chosen-time text-center">{{ timeStr }}</div>
            </div>
            <div class="service-desctiption">
                <p class="head">{{ head }}</p>
                <p class="description">{{ description }}</p>
                <p class="min-price" v-if="type === 'sum'">{{ price }} руб</p>
                <p class="min-price" v-else-if="type === 'sale'">
                    <span>{{ price }} руб</span>
                    <span class="old-price">{{ oldPrice }} руб</span>
                </p>
                <p class="min-price" v-else>от {{ minPrice }} руб</p>
                <FormButton v-if="!count" width="100%" height="32px" padding="0" font-weight="700" @click="this.type !== 'service' ? add(null, price) : showTimes()">
                    Добавить
                    <AddIcon width="20px" color="white" move-y="-1" :moveX="addIconWidthFirst" />
                </FormButton>
                <FormButton v-else width="100%" height="32px" padding="0" font-weight="700">
                    <MinusIcon width="20px" color="white" move-y="-1" :moveX="minusIconWidth" @click="decrement" />
                    {{ count }}
                    <AddIcon width="20px" color="white" move-y="-1" :moveX="addIconWidthSecond" @click="increment" />
                </FormButton>
            </div>
        </div>
        <div v-if="isBlur" class="choose-time">
            <div
                v-for="(time, index) in price" :key="time"
                :style="getTimeBlockStyle(index)"
                class="text-center"
            >
                <div class="choose-time-item" @click="add(time.id, time.price)">
                    <div class="time">{{ time.time }}</div>
                    <div class="price">{{ time.price }} руб</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormButton from "@/components/Form/FormButton";
import AddIcon from "@/components/Icon/AddIcon";
import MinusIcon from "@/components/Icon/MinusIcon";
import InfoIcon from "@/components/Icon/InfoIcon";
import {mapGetters} from "vuex";

export default {
    name: "ServiceCard",
    props: [
        'id',
        'head',
        'description',
        'type',
        'price',
        'oldPrice',
        'promoTypeCode',
        'typeObject'
    ],
    components: {
        FormButton,
        AddIcon,
        MinusIcon,
        InfoIcon
    },
    data() {
        return {
            minPrice: null,
            isBlur: false,
        };
    },
    computed: {
        ...mapGetters({
            serviceCountById: 'data/getCountByServiceId',
            moneyCertByPrice: 'data/getCertificatesCountByPrice',
            promoCount: 'data/getPromoCountByPromoId',
            getServiceById: 'data/getServiceById'
        }),
        service() { return this.getServiceById(this.id); },
        count() {
            return this.type === 'sum'
                ? this.moneyCertByPrice(this.price)
                : (
                    this.type === 'sale'
                        ? this.promoCount(this.id)
                        : this.serviceCountById(this.id)
                );
        },

        timeStr() { return this.service ? this.service.timeStr : null; },

        /**
         * Return card class name by type
         * @return String
         */
        getTypeClass() { return !this.type || this.type === 'service' ? 'service-card' : 'sum-card'; },
        addIconWidthFirst() { return this.$root.layout === 'mobile' ? 15 : 35; },
        addIconWidthSecond() { return this.$root.layout === 'mobile' ? 35 : 55; },
        minusIconWidth() { return this.$root.layout === 'mobile' ? -50 : -70; },

    },
    methods: {
        getTimeBlockStyle(index) {
            const offset = 100 / this.price.length;
            return `width: ${offset}%; left: ${index * offset}%;`
        },
        showTimes() { this.isBlur = true; },

        /**
         * Add service to cart
         * @param {Number} timeId
         * @param {Number} price
         */
        add(timeId, price) {
            if (this.type === 'sum') {
                this.$store.dispatch('data/addMoneyCertificate', price);
            } else if (this.type === 'sale') {
                const promoObj = this.typeObject;
                const promoTypeCode = this.promoTypeCode;
                const promoId = this.id;
                this.$store.dispatch('data/addPromo', {promoId, promoTypeCode, price, promoObj});
            }
            else {
                this.isBlur = false;
                const serviceId = this.id;
                this.$store.dispatch('data/addService', {serviceId, timeId});
            }
        },
        increment() {
            if (this.type === 'sum') {
                this.$store.dispatch('data/moneyCertIncrement', this.price);
            } else if (this.type === 'sale') {
                this.$store.dispatch('data/promoIncrement', this.id);
            } else {
                this.$store.dispatch('data/serviceIncrement', this.id);
            }
        },
        decrement() {
            if (this.type === 'sum') {
                this.$store.dispatch('data/moneyCertDecrement', this.price);
            } else if (this.type === 'sale') {
                this.$store.dispatch('data/promoDecrement', this.id);
            } else {
                this.$store.dispatch('data/serviceDecrement', this.id);
            }
        }
    },
    beforeMount() {
        if (this.type === 'sale') {
            this.minPrice = this.price
        } else if (this.type !== 'sum') {
            this.minPrice = Math.min(...this.price.map(p => p.price));
        }
    }
}
</script>

<style scoped lang="scss">
p { margin: 0; }

.card {
    padding: 16px;
    margin: 8px 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 10px;

    display: table;
    width: 100%;
    height: 172px;

    position: relative;
}

.card>div { display: table-row; }
.choose-time {
    position: absolute;
    top: 16px; right: 16px; bottom: 16px; left: 16px;
}
.choose-time>div {
    position: absolute;
    height: 100%;
    padding-top: 44px;
}

.blur {
    filter: blur(4px);
    background: rgba(0, 0, 0, 0.2);
}

.card>div>div { display: table-cell; }

.service-img {
    position: relative;
    width: 140px;
    background: url("@/assets/img/CardBackground.png") no-repeat;
}
.sum-card .service-img {
    background: url("@/assets/img/moneyCertificateBackground.jpg");
    background-size: cover;
}

.service-img .chosen-time {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 36px;
    padding-top: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    border-radius: 0 0 5px 5px;
}

.service-img .info {
    position: absolute;
    top: 9px;
    right: 9px;
}

.service-desctiption {
    padding-left: 16px;
}

.head {
    min-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.head, .min-price {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #3A3A3A;
}

.description {
    min-height: 32px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #A6A6AA;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
    -webkit-box-orient: vertical;
}

.head, .description { margin-bottom: 4px; }
.min-price { margin-bottom: 8px; }
.min-price .old-price {
    color: red;
    text-decoration: line-through;
    margin-left: 10px;
}

.choose-time-item {
    margin: auto;
    padding: 4px 6px;
    width: 75px;
    height: 52px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 8px;

    font-size: 10px;
    line-height: 20px;
    cursor: pointer;
}
.choose-time-item:hover { background: white; }

.choose-time-item .time {
    font-weight: 700;
    color: #2C2C2C;
    border-bottom: 0.5px solid #1D1D1D;
    margin-bottom: 2px;
}
.choose-time-item .price {
    font-weight: 500;
    color: #2C2C2C;
}

@media screen and (max-width: $main-width) {
    .choose-time-item {
        width: 60px;
        font-size: 10px;
        height: auto;
    }
}
</style>