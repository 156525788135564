<template>
    <div class="cart-list">
        <CartListItem
            v-for="service in moneyCerts" :key="service"
            :id="String(service.price)"
            :name="moneyCertTitle"
            :count="service.count"
            :price="String(service.price)"
            type="certificate"
        />
        <CartListItem
            v-for="service in chosenServices" :key="service"
            :id="service.id"
            :name="service.name"
            :time="service.time"
            :count="service.count"
            :price="String(service.price)"
            type="service"
        />
        <CartListItem
            v-for="promo in promos" :key="promo"
            :id="promo.id"
            :name="promo.name"
            :count="promo.count"
            :price="String(promo.price)"
            type="promo"
        />
        <CartListItem
            v-if="certData.certType === '1' && certData.deliveryType === 'courier' && withDelivery"
            name="Доставка"
            :price="cartSum < freeDeliveryMinSum ? String(deliveryPrice) : '0'"
            type="delivery"
        />
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import CartListItem from "@/components/Cart/CartListItem";

export default {
    name: "CartList",
    props: {
        withDelivery: Boolean
    },
    components: {
        CartListItem
    },
    computed: {
        ...mapGetters({
            services: 'data/services',
            moneyCerts: 'data/moneyCertificates',
            promos: 'data/promos',
            certData: 'data/certData',
            facilitiesTimes: 'facilities/facilitiesTimesArr',
            getFacilityById: 'facilities/getFacilityById',
            moneyCertTitle: 'facilities/moneyCertificateTitle',
            deliveryPrice: 'cart/deliveryPrice',
            cartSum: 'data/cartSum',
            freeDeliveryMinSum: "cart/freeDeliveryMinSum",
        }),
        chosenServices() {
            let self = this;
            return this.services.map(service => {
                const facility = self.getFacilityById(service.id);
                return {
                    id: service.id,
                    name: facility.name,
                    time: self.facilitiesTimes[service.timeId],
                    count: service.count,
                    price: service.price,
                };
            });
        },
    },
}
</script>

<style scoped>
.cart-list {
    width: 100%;
}
</style>