<template>
    <div class="textarea-div">
        <textarea
            :style="style"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :placeholder="placeholder"
        ></textarea>
    </div>
</template>

<script>
export default {
    name: "FormTextarea",
    props: {
        modelValue: String,
        width: String,
        placeholder: String
    },
    computed: {
        style () {
            let rule = '';
            if (this.width) { rule += `width: ${this.width};`; }
            return rule;
        }
    },
}
</script>

<style scoped>
textarea {
    height: 69px;
    border: 1px solid #BDC5DB;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #A5B0CB;
    outline: none;
    max-width: 100%;
}
</style>