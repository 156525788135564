<template>
    <div class="main">
        <div v-if="facilities">
            <div class="icons">
                <q-icon
                    :name="icon.arrowLeft"
                    size="17px"
                    v-on:click="prevStep"
                    style="color: #DCE1E8;"
                    class="cursor-pointer"
                />
            </div>
            <p class="title text-center">
                {{ title }}
                <InfoIcon
                    color="#03081A" width="22" moveY="4" style="margin-left: 10px;"
                    popupText="Вы можете собрать неограниченное количество сертификатов на услуги или сумму"
                />
            </p>
            <CatalogHead class="catalog-head" />
            <CatalogBody class="catalog-body" />
            <CatalogCart v-if="showCart" class="catalog-cart" @closeCart="showCart = false" />
        </div>
        <div v-else>
            <CatalogSkeleton />
        </div>
        <CatalogFooter @showCart="showCart = !showCart" />
    </div>
</template>

<script>
import { fasChevronLeft } from '@quasar/extras/fontawesome-v6';
import InfoIcon from "@/components/Icon/InfoIcon";
import CatalogHead from "@/components/Catalog/CatalogHead";
import CatalogBody from "@/components/Catalog/CatalogBody";
import CatalogFooter from "@/components/Catalog/CatalogFooter";
import CatalogSkeleton from "@/components/Catalog/CatalogSkeleton";
import {mapGetters} from "vuex";
import CatalogCart from "@/components/Catalog/CatalogCart";

export default {
    name: "CertificateCatalog",
    components: {
        CatalogFooter,
        InfoIcon,
        CatalogHead, CatalogBody,
        CatalogSkeleton,
        CatalogCart
    },
    data() {
        return {
            title: 'Каталог',
            icon: {
                arrowLeft: null,
            },
            showCart: false,
        }
    },
    computed: {
        ...mapGetters({
            facilities: 'facilities/facilities'
        })
    },
    methods: {
        prevStep() {
            this.$store.dispatch('step/prevStep');
        },
    },
    created() {
        this.icon.arrowLeft = fasChevronLeft;
    }
}
</script>

<style>
.main {
    padding-left: 24px;
    padding-right: 24px;
}
</style>
<style scoped lang="scss">
.icons {
    padding: 0 15px 6px;
}

.catalog-head {
    margin: 0 8px 16px;
}

.catalog-body {
    margin: 0 8px;
}

.catalog-cart {
    position: absolute;
    margin-top: -1072px;
    margin-left: -32px;
    z-index: 10;
}
</style>