import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import iconSet from 'quasar/icon-set/fontawesome-v6'

// To be used on app.use(Quasar, { ... })
export default {
    config: {
        steps: [
            'ChooseCity',
            'BuyOrActivate'
        ],
        css: {
            primary: '#5A305D',
            primaryHover: '#3D1152',
            secondary: '#EFF1F4',
            secondaryHover: '#E9ECF0'
        }
    },
    plugins: {
    },
    iconSet: iconSet
}