<template>
    <div :class="['catalog-footer', {'disabled': !Boolean(cartCount)}]">
        <div class="row">
            <div class="col-6">
                <p class="cart-count">Выбрано {{ cartCount }}:</p>
                <p class="cart-sum">{{ cartSum }} руб</p>
            </div>
            <div class="col-6 text-right continue">
                <FormButton layout="outline" width="32px" height="32px" padding="5px 0 0 0" class="shopping-bag-btn" @click="showCart" >
                    <ShoppingBagIcon width="16px" color="#5A305D" />
                </FormButton>
                <FormButton :width="$root.isMobile ? '100px' : '132px'" height="32px" padding="0" font-weight="700" class="float-right" @click="shoppingCart">
                    Продолжить
                </FormButton>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import FormButton from "@/components/Form/FormButton";
import ShoppingBagIcon from "@/components/Icon/ShoppingBagIcon";
import {fire} from "@/helpers/analytics";

export default {
    name: "CatalogFooter",
    components: {
        FormButton,
         ShoppingBagIcon
    },
    computed: {
        ...mapGetters({
            cartSum: 'data/cartSum',
            cartCount: 'data/cartServiceCount'
        })
    },
    methods: {
        shoppingCart() {
            this.$store.dispatch('step/setStep', 'ShoppingCart');
            fire("goToShoppingCart", {});
        },
        showCart() {
            if (this.cartCount) {
                this.$emit('showCart');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.catalog-footer {
  margin: 0 auto;
  padding: 16px 24px;
  box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.08);
}

.catalog-footer.disabled {
    color: #C0C6CE !important;
}

p { margin: 0; }
p.cart-count {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $primary-color;
}
p.cart-sum {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #3A3A3A;
}
.continue {
    padding-top: 10px;
}

.shopping-bag-btn { margin-right: 8px; }
</style>