<template>
    <div class="catalog-cart">
        <div class="cart">
            <div class="head">
                <div class="icons">
                    <q-icon
                        :name="icon.xmark"
                        size="17px"
                        v-on:click="$emit('closeCart')"
                        style="color: #DCE1E8; float: right;"
                        class="cursor-pointer"
                    />
                </div>
                <p class="title text-center">
                    {{ title }}
                    <InfoIcon
                        color="#03081A" width="22" moveY="4" style="margin-left: 10px;"
                        popupText="Вы можете собрать неограниченное количество сертификатов на услуги или сумму"
                    />
                </p>
            </div>
            <div class="body">
                <CartList />
            </div>
        </div>
    </div>
</template>

<script>
import {fasXmark} from "@quasar/extras/fontawesome-v6";
import CartList from "@/components/Cart/CartList";

export default {
    name: "CatalogCart",
    components: {
        CartList
    },
    data() {
        return {
            icon: {
                xmark: null
            },
            title: 'Корзина'
        }
    },
    created() {
        this.icon.xmark = fasXmark;
    }
}
</script>

<style scoped lang="scss">
p { margin: 0 }
.catalog-cart {
    height: 1072px;
    width: 460px;
    backdrop-filter: blur(2px);
    position: relative;
}

.cart {
    backdrop-filter: none;
    background-color: white;
    height: 284px;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-shadow: inset 0 -2px 16px -2px rgb(0 0 0 / 8%), 0 2px 16px 2px rgb(0 0 0 / 8%);
    border-radius: 8px 8px 0 0;
}
.cart .head {
    padding: 38px 22px 16px;
}

.icons {
    height: 12px;
    margin-bottom: 6px;
}

.body {
    padding: 0 32px;
    overflow-y: scroll;
    height: 180px;
}

@media screen and (max-width: $main-width) {
    .catalog-cart {
        width: 100%;
    }
}
</style>