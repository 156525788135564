<template>
    <span>
        <svg :style="style" :width="width" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.46634 0.933171C3.59224 0.7653 3.78984 0.666504 3.99967 0.666504H11.9997C12.2095 0.666504 12.4071 0.7653 12.533 0.933171L14.5289 3.59441C14.6151 3.70674 14.6663 3.84731 14.6663 3.99984V13.3332C14.6663 13.8636 14.4556 14.3723 14.0806 14.7474C13.7055 15.1225 13.1968 15.3332 12.6663 15.3332H3.33301C2.80257 15.3332 2.29387 15.1225 1.91879 14.7474C1.54372 14.3723 1.33301 13.8636 1.33301 13.3332V3.99984C1.33301 3.84733 1.38422 3.70678 1.47038 3.59445L3.46634 0.933171ZM2.66634 4.6665V13.3332C2.66634 13.51 2.73658 13.6795 2.8616 13.8046C2.98663 13.9296 3.1562 13.9998 3.33301 13.9998H12.6663C12.8432 13.9998 13.0127 13.9296 13.1377 13.8046C13.2628 13.6795 13.333 13.51 13.333 13.3332V4.6665H2.66634ZM12.6663 3.33317H3.33301L4.33301 1.99984H11.6663L12.6663 3.33317ZM5.33301 5.99984C5.7012 5.99984 5.99967 6.29831 5.99967 6.6665C5.99967 7.19694 6.21039 7.70565 6.58546 8.08072C6.96053 8.45579 7.46924 8.6665 7.99967 8.6665C8.53011 8.6665 9.03882 8.45579 9.41389 8.08072C9.78896 7.70565 9.99967 7.19694 9.99967 6.6665C9.99967 6.29831 10.2982 5.99984 10.6663 5.99984C11.0345 5.99984 11.333 6.29831 11.333 6.6665C11.333 7.55056 10.9818 8.39841 10.3567 9.02353C9.73158 9.64865 8.88373 9.99984 7.99967 9.99984C7.11562 9.99984 6.26777 9.64865 5.64265 9.02353C5.01753 8.39841 4.66634 7.55056 4.66634 6.6665C4.66634 6.29831 4.96482 5.99984 5.33301 5.99984Z" :fill="color ? color : iconColor" />
        </svg>
    </span>
</template>

<script>
export default {
    name: "ShoppingBagIcon",
    data() {
        return {
            iconWidth: 16,
            iconColor: 'black',
        }
    },
    props: {
        width: String,
        color: String,
        moveX: String,
        moveY: String
    },
    computed: {
        style () {
            let rule = '';
            if (this.moveX) { rule += `left: ${this.moveX}px;`; }
            if (this.moveY) { rule += `top: ${this.moveY}px;`; }
            return rule;
        }
    },
    created() {
        if (this.width) { this.iconWidth = parseInt(this.width); }
    }
}
</script>

<style scoped>
/*span { position: relative; }*/
/*svg { position: absolute; }*/
</style>
