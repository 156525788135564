<template>
    <div class="main">
        <div class="icons">
            <q-icon
                :name="icon.arrowLeft"
                size="17px"
                v-on:click="$store.dispatch('step/prevStep')"
                style="color: #DCE1E8;"
                class="cursor-pointer"
            />
        </div>
        <p class="title text-center">
            {{ title }}
        </p>
        <p class="text-center subtitle">{{ regionName }}</p>
        <CartBody :isValidate="validate" />
        <CartFooter @validate="beginValidate" />
    </div>
</template>

<script>
import { fasChevronLeft } from '@quasar/extras/fontawesome-v6';
import {mapGetters} from "vuex";
import CartBody from "@/components/Cart/CartBody";
import CartFooter from "@/components/Cart/CartFooter";

/**
 * @property {Store} $store
 */
export default {
    name: "ShoppingCart",
    components: {
        CartBody,
        CartFooter
    },
    data() {
        return {
            title: 'Оформление заказа',
            validate: false,
            icon: {
                arrowLeft: null,
            },
        }
    },
    computed: {
        ...mapGetters({
            region: 'regions/regionById',
            chosenRegion: 'data/region'
        }),
        regionName() {
            return this.region(this.chosenRegion).name;
        }
    },
    methods: {
        beginValidate() { this.validate = true; }
    },
    created() {
        this.icon.arrowLeft = fasChevronLeft;
    }
}
</script>

<style scoped lang="scss">
.title {
    margin-bottom: 0 !important;
}

.subtitle {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #616571;
}
</style>