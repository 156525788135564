<template>
    <div class="cert-type-block">
        <div class="cert-type">
            <p>Тип сертификата</p>
            <FormSelect
                width="100%"
                color="#03081A"
                :items="certTypes"
                v-model="certType"
            />
        </div>
        <div v-if="certType === '1'" class="delivery-type">
            <p>Тип доставки</p>
            <FormSelect
                width="100%"
                color="#03081A"
                :items="deliveryTypes"
                v-model="deliveryType"
            />
        </div>
        <div v-if="deliveryType === 'pickup' && certType === '1'" class="delivery">
            <p>Адрес салона “ТВОЙТАЙ”<span class="address-dot">&#8226;</span>{{ parlourWorkTime }}</p>
            <FormSelect
                width="100%"
                color="#03081A"
                :items="parlours" dataKey="address"
                v-model="parlour"
            />
        </div>
        <div v-if="deliveryType === 'courier' && certType === '1'" class="pickup-data">
            <FormInput placeholder="Введите адрес*" v-model="address" :layout="isValidate && !addressValidation ? 'danger' : ''" errorMsg="Адрес не найден, попробуйте еще раз" />
            <div class="row">
                <div class="col-6 entrance">
                    <FormInput placeholder="Подъезд*" v-model="entrance" mask="#" :layout="isValidate && entrance.length === 0 ? 'danger' : ''" errorMsg="Некорректный ввод" />
                </div>
                <div class="col-6 floor">
                    <FormInput placeholder="Этаж*" v-model="floor" mask="#" :layout="isValidate && floor.length === 0 ? 'danger' : ''" errorMsg="Некорректный ввод" />
                </div>
            </div>
            <FormInput placeholder="Квартира*" v-model="flat" mask="#" :layout="isValidate && flat.length === 0 ? 'danger' : ''" errorMsg="Некорректный ввод" />
            <FormInput placeholder="Укажите ФИО*" v-model="customerName" :layout="isValidate && !nameValidation ? 'danger' : ''" errorMsg="Некорректный ввод" />
        </div>
    </div>
</template>

<script>
import FormSelect from "@/components/Form/FormSelect";
import FormInput from "@/components/Form/FormInput";
import {mapGetters} from "vuex";
import validation from "@/store/modules/validation";

export default {
    name: "CertificateType",
    props: ['isValidate'],
    components: {
        FormSelect,
        FormInput
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            certTypes: 'cart/certTypes',
            deliveryTypes: 'cart/deliveryTypes',
            certData: 'data/certData',
            parlours: 'parlour/parlours',
            getParlourById: 'parlour/getParlourById'
        }),
        certType: {
            get() { return this.certData.certType; },
            set(value) {
                const key = 'certType';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        deliveryType: {
            get() { return this.certData.deliveryType; },
            set(value) {
                const key = 'deliveryType';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        address: {
            get() { return this.certData.address; },
            set(value) {
                const key = 'address';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        entrance: {
            get() { return this.certData.entrance; },
            set(value) {
                const key = 'entrance';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        floor: {
            get() { return this.certData.floor; },
            set(value) {
                const key = 'floor';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        customerName: {
            get() { return this.certData.customerName; },
            set(value) {
                const key = 'customerName';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        parlour: {
            get() { return this.certData.parlour; },
            set(value) {
                const key = 'parlour';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        flat: {
            get() { return this.certData.flat; },
            set(value) {
                const key = 'flat';
                this.$store.dispatch('data/setCertData', {key, value});
            }
        },
        parlourWorkTime() {
            if (!this.parlour) return '';
            const parlour = this.getParlourById(this.parlour);
            if (!parlour || !parlour.workTime) return '';
            const parlourWorkTime = parlour.workTime['1'];
            return `${parlourWorkTime.start} - ${parlourWorkTime.finish}`;
        },
        addressValidation() {
            return validation.address(this.address);
        },
        nameValidation() {
            return validation.name(this.customerName);
        }
    },
    created() {
        this.$store.dispatch('data/setCertData', this.parlours[0].id);
    }
}
</script>
<style scoped>
.cert-type, .delivery-type, .pickup-data, .delivery { margin-bottom: 16px; }

.pickup-data>div { margin: 16px 0; }
.pickup-data .entrance { padding-right: 8px; }
.pickup-data .floor { padding-left: 8px; }

p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #616571;
}

.address-dot {
    padding: 0 16px;
}
</style>