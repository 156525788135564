<template>
    <div class="select-div">
        <select :style="style" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
            <option v-if="placeholder" value="" selected disabled hidden>{{ placeholder }}</option>
            <option v-for="item in items" :key="item" :value="item.id">{{ item[dataKey ? dataKey : 'name'] }}</option>
        </select>
        <q-icon :name="arrowDown" size="13px" class="cursor-pointer arrow-icon" />
    </div>
</template>

<script>
import { fasChevronDown } from '@quasar/extras/fontawesome-v6';

export default {
    name: "FormSelect",
    props: {
        width: String,
        items: Array,
        modelValue: String,
        placeholder: String,
        color: String,
        dataKey: String
    },
    computed: {
        style () {
            let rule = '';
            if (this.width) { rule += `width: ${this.width};`; }
            if (this.color) { rule += `color: ${this.color};`; }
            return rule;
        }
    },
    created() {
        this.arrowDown = fasChevronDown;
    }
}
</script>

<style scoped lang="scss">
    .select-div {
        position: relative;
    }

    select {
        background: #EFF1F4;
        border-radius: 8px;
        padding: 10px 16px;
        border: 1px solid #EFF1F4;
        color: #A0A9B6;
        appearance: none;
    }

    select:focus {
        border: 1px solid $primary-color;
        box-shadow: 0 0 6px rgba(90, 48, 93, 0.5);
    }

    .select-div .arrow-icon {
        color: #A0A9B6;
        position: absolute;
        top: 14px;
        right: 16px;
    }

    select option {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 12px 16px;
    }

    select option:hover {
        color: $primary-color;
    }
</style>