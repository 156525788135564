<template>
    <div>
        <q-input
            borderless
            :input-class="['custom-form-input', layout]"
            :placeholder="placeholder"
            :mask="mask"
            reverse-fill-mask
            v-model="text"
            :maxlength="maxLength"
        />
        <p v-if="layout === 'danger' && errorMsg" class="error-message">{{ errorMsg }}</p>
    </div>
</template>

<script>
export default {
    name: "FormInput",
    props: [
        'placeholder',
        'modelValue',
        'mask',
        'layout',
        'errorMsg',
        'lang', // ru|en or ru or ru|en|di
        'maxLength'
    ],
    data() {
        return {
            text: '',
            langRegexp: null
        }
    },
    watch: {
        /**
         * Update model value when property was changed
         * @param {String} newText
         * @return void
         */
        text(newText) {
            if (this.lang) {
                this.text = newText.replace(this.langRegexp,'');
            }
            this.$emit('update:modelValue', this.text);
        }
    },
    methods: {
        updateText(text) { this.text = text; },
    },
    created() {
        this.text = this.modelValue;
        if (this.lang) {
            let re = '';
            const lang = this.lang.split('|');
            if (lang.includes('di')) {
                re += '0-9';
            }
            if (lang.includes('en')) {
                re += 'a-zA-Z';
            }
            if (lang.includes('ru')) {
                re += 'а-яА-ЯёЁ';
            }
            this.langRegexp = new RegExp(`[^${re}]`, 'g');
        }
    }
}
</script>

<style>
.custom-form-input {
    padding: 10px 16px !important;
    height: 40px !important;
    background: #EFF1F4 !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #03081A !important;
}

.custom-form-input.danger {
    border: 1px solid #F64444 !important;
    color: #F64444 !important;
}

.q-field__control { height: auto !important; }
</style>
<style scoped lang="scss">
.error-message {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #F74444;
    margin: 0;
}
</style>
