import axios from "axios";

function defaultState() {
    return {
        apiUrl: process.env.VUE_APP_URL_API + '/public-api/paydoc/get-parlours',
        parlours: null
    };
}

/**
 * Модуль для салонов с данными
 */
export default {
    namespaced: true,
    state: defaultState,
    getters: {
        getState(state) { return state; },
        parlours(state) { return state.parlours; },
        getParlourById(state) {
            return function(parlourId) {
                return state.parlours.find(parlour => parlour.id === parlourId);
            }
        }
    },
    actions: {
        setState(context, newState) { context.commit('setState', newState); },
        reset(context) { context.commit('reset'); },

        /**
         * Fetch api and set parlours array
         * @param context
         * @param {String} regionId
         */
        async set(context, regionId) {
            let response = await axios.get(context.state.apiUrl, {
                headers: { Authorization: `ApiKey ${process.env.VUE_APP_TOKEN}` },
                params: {
                    per_page: -1,
                    'search[region]': regionId,
                    'search[withTime]': true,
                    'search[pickupAvailable]': true
                }
            });
            response = response.data;
            if (response.success) {
                const parlours = response.data;
                context.commit('setParlours', parlours);
                if (parlours.length > 0) {
                    context.rootState.data.certData.parlour = parlours[0].id;
                }
            }

            return response.success;
        }
    },
    mutations: {
        setState(state, newState) {
            for (let prop in state) {
                state[prop] = newState[prop];
            }
        },
        reset(state) {
            const defState = defaultState();
            Object.keys(defState).forEach(stateKey => {
                state[stateKey] = defState[stateKey];
            })
        },

        setParlours(state, parloursData) { state.parlours = parloursData; }
    }
}