<template>
    <div class="result-sum row">
        <div class="col-4 text">итого</div>
        <div class="col-8 text-right sum">{{ cartSum }} руб</div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ResultSum",
    computed: {
        ...mapGetters({
            cartSum: 'data/cartSumWithDelivery',
        })
    }
}
</script>

<style scoped>
.result-sum {
    width: 100%;
    padding: 16px 0;
}

.result-sum .text {
    font-weight: 800;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: #A6A6AA;
}

.result-sum .sum {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #3A3A3A;
}
</style>